import React from 'react';
import { graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { useBreakpoint } from "gatsby-plugin-breakpoints"

import "../components/layout.css"

const IndexPage = ({ data }) => {

  const breakpoints = useBreakpoint();
  
  return (
    <Layout>
      <Seo title="Lar Darte | Lar Darte" />
      <div className="fullWidth" >

        {breakpoints.md || breakpoints.ipad ? (
          <>
            <div class="container">

              <div className='wrapper'>
                <div class="principal">
                  <StaticImage
                    src="../images/logo-white.svg"
                    loading="eager"
                    className='image'
                    quality={100}
                    formats={["auto", "webp", "avif", "svg"]}
                    alt=""
                  />
                </div>

                <div className='socialUp'>
                  {data.globalJson.socialUp.map((socialUp, l) => (
                    <a href={socialUp.link} target="_blank" key={"rede" + l}>
                      <GatsbyImage image={getImage(socialUp.img)}
                        quality={100}
                        formats={["auto", "webp", "avif", "svg"]}
                        alt={socialUp.alt}
                        className="image"
                      />
                    </a>
                  ))}
                </div>

                <div class="linha">
                  {data.globalJson.linktree.map((linktree, l) => (
                    <ul class="links-list">
                      <li>
                        <a href={linktree.link} target="_blank" key={"social" + l}>
                          <GatsbyImage image={getImage(linktree.img)}
                            quality={100}
                            formats={["auto", "webp", "avif", "svg"]}
                            alt={linktree.alt}
                          />
                        </a>
                      </li>
                    </ul>
                  ))}
                </div>

                <div class="vertical"></div>

                <div className='redesBottom'>

                  <div className='redes'>
                    {data.globalJson.showroom.map((showroom, l) => (
                      <a href={showroom.link} target="_blank" key={"rede" + l}>
                        <GatsbyImage image={getImage(showroom.img)}
                          quality={100}
                          formats={["auto", "webp", "avif", "svg"]}
                          alt={showroom.alt}
                          className='image'
                        />
                      </a>
                    ))}
                    <br></br>
                  </div>


                  <div class="vertical"></div>

                  <div className='redes'>
                    {data.globalJson.fabrica.map((fabrica, l) => (
                      <a href={fabrica.link} target="_blank" key={"rede" + l}>
                        <GatsbyImage image={getImage(fabrica.img)}
                          quality={100}
                          formats={["auto", "webp", "avif", "svg"]}
                          alt={fabrica.alt}
                          className='image'
                        />
                      </a>

                    ))}

                  </div>
                </div>

                <div className='company'>
                  <div className='companyText'>
                    <p className='text'>{data.globalJson.info.showroom}</p>
                    <p className='textBottom'>{data.globalJson.info.aveiro}</p>
                  </div>
                  <div className='companyText'>
                    <p className='text'>{data.globalJson.info.fabrica}</p>
                    <p className='textBottom'>{data.globalJson.info.lardarte}</p>
                  </div>
                </div>

              </div>
            </div>
          </>)
          :
          (

            <>

              <div class="container">

                <div className='wrapper'>
                  <div class="principal">
                    <StaticImage
                      src="../images/logo-white.svg"
                      loading="eager"
                      className='image'
                      quality={100}
                      formats={["auto", "webp", "avif", "svg"]}
                      alt=""
                    />
                  </div>

                  <div className='socialUp'>
                    {data.globalJson.socialUp.map((socialUp, l) => (
                      <a href={socialUp.link} target="_blank" key={"rede" + l}>
                        <GatsbyImage image={getImage(socialUp.img)}
                          quality={100}
                          formats={["auto", "webp", "avif", "svg"]}
                          alt={socialUp.alt}
                          className="image"
                        />
                      </a>
                    ))}
                  </div>

                  <div class="linha">
                    {data.globalJson.linktree.map((linktree, l) => (
                      <ul class="links-list">
                        <li>
                          <a href={linktree.link} target="_blank" key={"social" + l}>
                            <GatsbyImage image={getImage(linktree.img)}
                              quality={100}
                              formats={["auto", "webp", "avif", "svg"]}
                              alt={linktree.alt}
                            />
                          </a>
                        </li>
                      </ul>
                    ))}
                  </div>

                  <div class="vertical"></div>

                  <div className='redesBottom'>

                    <div className='redes'>
                      {data.globalJson.showroom.map((showroom, l) => (
                        <a href={showroom.link} target="_blank" key={"rede" + l}>
                          <GatsbyImage image={getImage(showroom.img)}
                            quality={100}
                            formats={["auto", "webp", "avif", "svg"]}
                            alt={showroom.alt}
                            className='image'
                          />
                        </a>
                      ))}
                      <br></br>
                    </div>


                    <div class="vertical"></div>

                    <div className='redes'>
                      {data.globalJson.fabrica.map((fabrica, l) => (
                        <a href={fabrica.link} target="_blank" key={"rede" + l}>
                          <GatsbyImage image={getImage(fabrica.img)}
                            quality={100}
                            formats={["auto", "webp", "avif", "svg"]}
                            alt={fabrica.alt}
                            className='image'
                          />
                        </a>

                      ))}

                    </div>
                  </div>

                  <div className='company'>
                    <div className='companyText'>
                      <p className='text'>{data.globalJson.info.showroom}</p>
                      <p className='textBottom'>{data.globalJson.info.aveiro}</p>
                    </div>
                    <div className='companyText'>
                      <p className='text'>{data.globalJson.info.fabrica}</p>
                      <p className='textBottom'>{data.globalJson.info.lardarte}</p>
                    </div>
                  </div>

                </div>
              </div>

            </>
          )}

      </div>


    </Layout >
  )
}

export default IndexPage




export const IndexQuery = graphql`
  query globalJson {
    globalJson {
      info{
        name
        company
        showroom
        fabrica
        aveiro
        lardarte
      }
      linktree {
        link
        alt
        img{
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      socialUp {
        alt
        link
        img{
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      showroom{
        alt
        link
        img{
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      fabrica{
        alt
        link
        img{
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
